<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--主数据类型-->
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          size="mini"
          label-width="68px"
        >
          <el-form-item label="名称" prop="typeName">
            <el-input
              v-model="queryParams.typeName"
              placeholder="请输入主数据类型关键词"
              clearable
              size="mini"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="类型标识" prop="typeKey">
            <el-input
              v-model="queryParams.typeKey"
              placeholder="请输入主数据类型关键词"
              clearable
              size="mini"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item>
           <etpSystem
           :enterpriseSecret="queryParams.enterpriseSecret"
           :appId="queryParams.appId"
            @getEtpSecret="initEtpSecret"
            @getAppId="initAppId"
           ></etpSystem>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              v-preventReClick
              @click="handleAdd"
              >新增</el-button
            >
          </el-col>
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          v-loading="loading"
          :data="dataList"
          size="mini"
          fit
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="主键"
            header-align="left"
            align="left"
            key="guid"
            prop="guid"
            width="280"
          />
          <el-table-column
            label="名称"
            header-align="left"
            align="left"
            key="typeName"
            prop="typeName"
          />
          <!-- <el-table-column
            label="类型英文名称"
            header-align="left"
            align="left"
            key="typeNameEn"
            prop="typeNameEn"
          /> -->
          <el-table-column
            label="标识"
            header-align="left"
            align="left"
            key="typeKey"
            prop="typeKey"
            width="200"
          />
          <el-table-column
            label="所属系统"
            header-align="left"
            align="left"
            key="appName"
            prop="appName"
          />
          <el-table-column
            label="所属企业"
            header-align="left"
            align="left"
            key="enterpriseSecretName"
            prop="enterpriseSecretName"
          />
          <el-table-column
            label="创建时间"
            header-align="right"
            align="right"
            prop="createTime"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="160"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleUpdate(scope.row)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-view"
                v-preventReClick
                @click="handleView(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="1000px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row>
         <el-col :span="24">
                <el-form-item label="所属企业" prop="enterpriseSecret">
                  <el-select v-loading="etploading"  v-model="form.enterpriseSecret" @change="getEtpSystem">
                    <el-option v-for="item in enterpriseOptions" :key="item.code"  :value="item.code" :label="item.name"></el-option>
                  </el-select>
                  <!-- <el-input
                   
                    placeholder="请输入企业标识"
                  >
                  </el-input> -->
                </el-form-item>
              </el-col>
        </el-row>
        <el-row v-if="form.enterpriseSecret">
              <el-col :span="24">
                <el-form-item label="所属系统" prop="appId">
                    <el-select v-loading="etpSystemloading" v-model="form.appId">
                    <el-option  v-for="item1 in enterpriseSystemOptions" :key="item1.code"  :value="item1.code" :label="item1.name"></el-option>
                  </el-select>
                  <!-- <el-input v-model="form.appId" placeholder="请输入系统appId">
                  </el-input> -->
                </el-form-item>
              </el-col>
        </el-row>
        <el-row v-if="form.appId">
          <el-col>
            <el-form-item label="类型中文名称" prop="typeName">
              <el-input   v-model="form.typeName" placeholder="请输入类型中文名称"   />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.appId">
          <el-col>
            <el-form-item label="类型英文名称" prop="typeNameEn">
             <el-input   v-model="form.typeNameEn" placeholder="请输入类型英文名称"   />
            </el-form-item>
          </el-col>
        </el-row>
         <el-row v-if="form.appId">
          <el-col >
            <el-form-item label="类型标识" prop="typeKey" >
               <el-input   v-model="form.typeKey" placeholder="请输入类型标识"   />
            </el-form-item>
          </el-col>
        </el-row>

      <el-row v-if="form.appId">
        <el-col >
            <el-form-item label="是否树形结构" prop="isTree" >
                <el-radio-group v-model="form.isTree">
                        <el-radio label="1">是</el-radio>
                        <el-radio label="0">否</el-radio>
                    </el-radio-group>
            </el-form-item>
        </el-col>
      </el-row>
            </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-preventReClick
          >确 定</el-button
        >
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listEnterprise
} from '@/api/system/enterprise'
import {
  listApplication
} from '@/api/system/application'
import etpSystem from '@/components/EtpSystem/index.vue'
import {
  list,del,get,update,add
} from '@/api/maindata/datatype'
import { getMenuPriByMenuId } from '@/api/menu/index'
export default {
  name: 'datatype',
  components:{
     etpSystem
  },
  data() {
    return {
    etploading:false,
    etpSystemloading:false,
      menuPriPath: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      //分类列表
      categoryOptions:[],
      enterpriseOptions:[],//企业信息列表
      enterpriseSystemOptions:[],//企业信息对应的系统
      etploading:false,
      etpSystemloading:false,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        typeName: undefined,
        ishow:1,
        typeKey: undefined,
        enterpriseSecret: undefined,
        appId:undefined
      },
      // 表单参数
      form: {},
      defaultProps: {
        children: 'childrens',
        label: 'name'
      },
      // 表单校验
      rules: {
        typeName: [
          { required: true, message: '类型中文名称不能为空', trigger: 'blur' }
        ],
        typeKey: [
          { required: true, message: '分类不能为空', trigger: 'blur' }
        ],
        enterpriseSecret: [
          { required: true, message: '所属企业标识', trigger: 'change' }
        ],
        appId: [{ required: true, message: '所属系统AppId', trigger: 'change' }]
        
      }
    }
  },
  created() {
    this.getRolePriList()
    this.getEnterpriseList()
    this.getList()
  },
  methods: {
    /** 查询角色列表权限 */
    getRolePriList() {
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    initEtpSecret(val){
      this.queryParams.enterpriseSecret=val
    },
    initAppId(val){
      this.queryParams.appId=val
    },
        /**获取企业列表 */
    getEnterpriseList() {
      //debugger
      this.etploading = true
      const data={
                currentPage: 1,
        pageSize: 100
      }
      listEnterprise(data).then((res) => {
        this.etploading=false
        if (res.data.success) {
          let enterpriseList= res.data.result.records
          enterpriseList.forEach(element => {
            let etp={}
            etp.code=element.enterpriseSecret
            etp.name=element.name
            this.enterpriseOptions.push(etp)
          })
          console.log(this.enterpriseOptions)
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    //获取企业系统
    getEtpSystem(){
      this.etpSystemloading = true
      this.enterpriseSystemOptions=[]
      const data={
                currentPage: 1,
        pageSize: 100,
        enterpriseSecret:this.form.enterpriseSecret
      }
      listApplication(data).then((res) => {
        this.etpSystemloading=false
        if (res.data.success) {
          let appList= res.data.result.records
          appList.forEach(element => {
            let app={}
            app.code=element.appid
            app.name=element.name
            this.enterpriseSystemOptions.push(app)
          })
          console.log(this.enterpriseSystemOptions)
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    // 获取列表
    getList() {
      this.loading = true
      list(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.dataList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 取消按钮（数据权限）
    cancelDataScope() {
      this.openDataScope = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        guid: undefined,
        name: undefined,
        orderNum: undefined
      }
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.queryParams.enterpriseSecret=undefined
      this.queryParams.appId=undefined
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.form.isTree='0';
      this.title = '添加类型';
    },
    // 查看
    handleView(row) {
      window.open(
        '/system/maindata/dataitem?menuGuid=68bd2ad2e23445c88208becf4b6d35dc&typeGuid=' + row.guid,
        '_blank'
      )
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      var data = {
        guid: row.guid
      }
      get(data).then((res) => {
        if (res.data.success) {
          this.form = res.data.result;
          if (
            this.form.isTree == 0 
          ) {
            this.form.isTree = '0'
          }else{
            this.form.isTree = '1'
          }
          this.open = true;
          this.title = '修改类型';
        } else {
          this.$msgError(res.data.message);
        }
      })
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.guid != undefined) {
            update(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            this.form.ishow=1;
            add(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.typeName + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: row.guid
          }
          return del(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    },
  }
}
</script>
